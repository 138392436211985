import React, { useEffect } from "react"
import { store } from "react-notifications-component"
import FileSaver from 'file-saver'
import useSiteMetadata from "./SiteMetaData"

const Download = () => {
  const {link, downloadLink} = useSiteMetadata()

  useEffect(()=>downloadFunc(), [])

  function downloadFunc() {
    download(downloadLink)
  }

  return(
    <div className="section">
      <div className="container has-text-centered">

        <div className="columns">

          <div className="column">
            <div
              className="title is-padded-bottom has-text-weight-normal has-text-white is-size-2-widescreen is-size-3-desktop is-size-4-tablet is-size-5-mobile">
              Step 1. Your download will begin in a moment. Else you can click on
              <a href='#' onClick={downloadFunc} className="is-link has-text-danger">
                &nbsp;this link&nbsp;
                </a>
              to download.
            </div>
            <div className="title is-padded-bottom has-text-weight-normal has-text-white is-size-2-widescreen is-size-3-desktop is-size-4-tablet is-size-5-mobile" >
              Step 2. Locate the ZIP file on your computer and unzip it.
            </div>
            <div className="title is-padded-bottom has-text-weight-normal has-text-white is-size-2-widescreen is-size-3-desktop is-size-4-tablet is-size-5-mobile">
              Step 3. Go to <a href="chrome://extensions/" target="_blank">chrome://extensions/</a> and check the box for Developer mode in the top right.
            </div>
            <div className="title is-padded-bottom has-text-weight-normal has-text-white is-size-2-widescreen is-size-3-desktop is-size-4-tablet is-size-5-mobile">
              Step 4. Click the Load unpacked extension button and select the unzipped folder for your extension to install it.
            </div>
            <div className="title is-padded-bottom has-text-weight-normal has-text-white is-size-2-widescreen is-size-3-desktop is-size-4-tablet is-size-5-mobile">
              Video Tutorial: <a href={"https://youtu.be/SGoqpmr6DzE"} target={"_blank"}>Here</a>
            </div>
            <div className="horizantal-line is-padded-bottom"/>
            {/*<div
              className="title is-padded-bottom has-text-white is-size-1-widescreen is-size-2-desktop is-size-3-tablet is-size-4-mobile"
              id="how-to-section">
              How to Install
            </div>
            <div className="iframe-container">
              <iframe
                width="560px"
                height="315px"
                title="Video Tutorial | Beatshine"
                src="https://youtube.com/embed/3eYi_wtQSMA"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />+
            </div>*/}
          </div>

        </div>

      </div>
    </div>
  )
}

export const download = (link) => {

  FileSaver.saveAs(link, "profilemate.zip")
  store.addNotification({
    title: '',
    message: 'Your Download will Start Shortly',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ["animated", "fadeIn"],
    animationOut: ['animated', "faeOut"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  })
}

export default Download
