import React, {useState} from 'react'
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import isMobile from "is-mobile"
import { Link } from 'react-scroll'
import FileSaver from 'file-saver'
import { store } from 'react-notifications-component'
import useSiteMetadata from "./SiteMetaData"
import { download } from "./Download"

const NavigationBar = () => (
  <nav className="navbar">
    <div className="container">

      <div className="navbar-menu">
        <a
          href="/"
          className='navbar-start'
          title="Profilemate"
          onClick={() => {
            trackCustomEvent({
              category: "Logo Button Navbar",
              action: "Click",
              label: "Get Extension Button Navigation Bar"
            })
            return true
          }}
        >
          <img src="/logo/beatshine-logo-full.png" alt="Profilemate" className="logo-img" />
        </a>

        <div className="navbar-end is-size-5-tablet is-uppercase is-right">
          <a
            className="navbar-item logo-link"
            href={''}
            target="_blank"
            title='How To Install'
            rel="noopener noreferrer"
            onClick={() => {
              trackCustomEvent({
                category: "How to install",
                action: "Click",
                label: "Get Extension Button Navigation Bar"
              })
              return true
            }}
          >
            <div className="is-size-5-tablet is-size-7-mobile button is-rounded is-danger">
              How to Install
            </div>
          </a>

          <a
            className="navbar-item logo-link"
            href={''}
            target="_blank"
            title='Download Chrome Extension'
            rel="noopener noreferrer"
            onClick={() => {
              /*trackCustomEvent({
                category: "Get Extension",
                action: "Click",
                label: "Get Extension Button Navigation Bar"
              })
              fetch('https://api.beatshine.com/beat/api/v1/download_extension', {
                method: 'GET'
              })
              return true
              */
            }}
          >
            <div className="is-size-5-tablet is-size-7-mobile button is-rounded is-danger">
              Get the chrome Extension
            </div>
          </a>
        </div>
      </div>

    </div>
  </nav>
)

const Navbar = () => {
  const [active, setActive] = useState(false)
  const [navBarActiveClass, setNavbarActiveClass] = useState('')
  const {link} = useSiteMetadata()

  function toggleHamburger() {
    // toggle the active boolean in the state
    setNavbarActiveClass(active ? '' : 'is-active')
    setActive(!active)
  }

    return (
      <nav
        className="navbar"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="m-container nav">
          <div className="navbar-brand">
            <div className="navbar-item">
              <a
                href="/"
                className='logo-link'
                title="Beatshine"
                onClick={() => {
                  trackCustomEvent({
                    category: "Logo Button Navbar",
                    action: "Click",
                    label: "Get Extension Button Navigation Bar"
                  })
                  return true
                }}
              >
                <img src="/logo/beatshine-logo-full.png" alt="BeatShine" className="logo-img" />
              </a>
            </div>
            {/* Hamburger menu */}
            <div
              className={` navbar-burger burger ${navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>

          <div
            id="navMenu"
            className={`navbar-menu ${navBarActiveClass}`}
          >

            <div className="navbar-end is-vcentered is-size-5-tablet is-uppercase has-text-centered">
              {/*<Link
                className="navbar-item logo-link"
                // to="pricing-section"
                // title='How To Install'
                // rel="noopener noreferrer"
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => {
                  window.open('https://medium.com/@beatshine1/what-is-beatshine-14912cf065d2?source=friends_link&sk=be08f17040095ca15297fbc3e8d2680f', '_blank')
                  trackCustomEvent({
                    category: "Blogs",
                    action: "Click blogs",
                    label: "Get Extension Button Navigation Bar"
                  })
                  return true
                }}
              >
                <div className="is-size-5-tablet is-size-6-mobile button is-rounded is-danger">
                  How to Install
                </div> 
                <div
                  className="button is-text is-size-5-desktop is-size-5-tablet is-size-6-mobile"
                  style={{
                    textDecoration: 'none'
                  }}
                >
                  BLOGS
                </div>
              </Link>*/}
              {/*
              <Link
                className="navbar-item logo-link"
                to='how-to-section'
                offset={-100}
                duration={1000}
                smooth={true}
                title='Download Chrome Extension'
                rel="noopener noreferrer"
                onClick={()=>{
                  download(link)
                  }}
              >
                <div className="is-size-5-tablet is-size-6-mobile button is-danger" style={{ borderRadius: '4px' }}>
                  Get the chrome Extension
                </div>
              </Link>*/}
            </div>
          </div>

        </div>
      </nav>
    )
}

export default Navbar
